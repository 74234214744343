@font-face {
  font-family: "Google Sans Regular";
  src: local("Google Sans Regular"),
    url("../public/fonts/GoogleSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono Regular";
  src: local("Roboto Mono Regular"),
    url("../public/fonts/RobotoMono-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono Bold";
  src: local("Roboto Mono Bold"),
    url("../public/fonts/RobotoMono-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "Google Sans Regular", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
