.FilterCard {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  position: absolute;
  width: calc(100% - 40px);
  height: calc(100vh - 40px);
  border-radius: 10px;
  margin: 20px;
  padding: 10px;
  z-index: 100;
}