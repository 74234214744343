.Background {
  position: absolute;
  width: 100%;
  height: 100%;
  
  canvas {
    position: absolute;
    left: 50%;
    top: calc(50% + 2.5em);
    transform: translate(-50%, -50%);
    z-index: 0;
  }
}